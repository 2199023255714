body {
  padding: 50px;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 5;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 0%;
  color: #58585A;
  font-size: 18px;
  line-height: 24px;
}

div.logo1 {
  float: left;
  display: block;
  position: relative;

}
div.logo2 {
  float: right;
  display: block;
  position: relative;

}

div.becoming1, div.becoming2 {
  clear: left;
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
}

div.becoming2 {
  clear: both;
  display: block;
  position: relative;
}

a {
  background-color: transparent;
  color: #00b140;
}

::selection {
  color: white;
  background: #00b140;
}

p.intro {
  text-align: center;
  font-weight: 400;
}

div.pledge {
  clear: left;
  border: solid 2px #58585A;
  margin:0 auto;
  width: 80%;
}


ul.pledge {
  margin-left: 0;
}

div.signup {
  clear: both; 
  float: center; 
  display: block; 
  position: relative;
  margin-top: 11px;
  text-align: center;
}

div.pride {
  clear: both;
  float: center;
  display: block;
  position: relative;
  margin-top: 11px;
  text-align: center;
  color: #00b140;
}

span.signup {
  border: 1px solid #136594;
  padding: 6px;
}

input.signup {
  border: none;
  height: 18px;
  font-family: system-ui;
  font-size: 16px;
  user-focus: true;
}

div.thanks {
  margin-top: 11px;
  text-align: center;
}

.button {
  margin-top: 10px;
  padding: 10px 15px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  border: 3px solid #08ac4b;
  border-radius: 2px;
  background-color: #00b140;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
  transition-delay: initial;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.w-button {
  display: inline-block;
  padding: 9px 15px;
  color: #fff;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

p.heading {
  color: #008e89;
  font-size: 46px;
  font-weight: 300;
  padding-top: 9px;
}

div.cc {
  clear: both;
  float: right;
  display: block;
  position: relative;
  margin-top: 11px;
  text-align: center;
  color: #00b140;
  font-size: 10px;
}
